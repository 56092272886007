import React from "react"

const ContactAddress = () => (
  <div className="contact-address">
    <p>
      <a href="tel: 0785726094">+40 785 726 094</a>
    </p>
  </div>
)

export default ContactAddress

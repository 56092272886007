import React from "react"
import { Helmet } from "react-helmet"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Title from "../components/elements/title"
import SubTitle from "../components/elements/subtitle"
import ContactForm from "../components/form"
import ContactAddress from "../components/ContactAddress"
import Reviews from "../components/Reviews"

const ContactPage = () => (
  <Layout>
    <SEO title="Contact" />
    <Helmet>
      <body className="contact-page" />
    </Helmet>
    <div className="section">
      <div className="container">
        <Title title="Hai să vorbim" />
        <SubTitle subtitle="Fie că vrei să pui un proiect pe picioare sau cauți să transformi un business, găsim o soluție pentru tine. Lasă-ne un mesaj aici și noi te vom contacta." />
      </div>
    </div>
    <div className="section">
      <div className="container">
        <div className="content address" data-sal="slide-up">
          <ContactForm />
          <ContactAddress />
        </div>
      </div>
    </div>
    <Reviews />
  </Layout>
)

export default ContactPage
